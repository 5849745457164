.space {
  flex-shrink: 0;
}

.space-1 {
	height: var(--space-1);
}

.space-2 {
  height: var(--space-2);
}

.space-3 {
	height: var(--space-3);
}

.space-4 {
  height: var(--space-4);
}

.space-5 {
	height: var(--space-5);
}

.space-6 {
  height: var(--space-6);
}

.hspace-1 {
	width: var(--space-1);
}

.hspace-2 {
  width: var(--space-2);
}

.hspace-3 {
	width: var(--space-3);
}

.hspace-4 {
  width: var(--space-4);
}

.hspace-5 {
	width: var(--space-5);
}

.hspace-6 {
  width: var(--space-6);
}

