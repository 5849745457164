.container {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 100%;
  padding: 16px;
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}