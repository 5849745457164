@value mobile, tablet from "styles/breakpoints.css";

.appToolBar {
	padding-left: 0 !important;
}

.mainContainer {
	display: flex;
}

.bottomNavigation {
	display: none;
}

.menuBox {
	width: 264px;
	flex-shrink: 0;
}

.mainBox {
	flex-grow: 1;
}

.drawer :global .MuiDrawer-paper {
	box-sizing: 'border-box';
	width: 264px;
	border-right: 0;
}

.menuContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
}

.topList {
	flex: 1;
}

.menuItemActive {
	border-radius: 16px;
	background-color: var(--color-primary-8p);
	color: var(--color-primary-main);
}

.menuItemIconActive {
	color: var(--color-primary-main) !important;
}

.compactMenuBox {
	width: 64px;
}

.compactDrawer :global .MuiDrawer-paper {
	box-sizing: 'border-box';
	width: 64px;
	border-right: 0;
	position: sticky;
}

.compactMenuLogo {
	display: none !important;
}

@media mobile {
	.mainContainer {
		display: block;
	}

	.compactMenuBox {
		display: none;
	}

	.iconsContainer {
		color: var(--color-primary-contrast-text);
	}

	.bottomNavigation {
		background: var(--background-paper-light);
		display: block;
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}
}